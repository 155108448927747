<template>
  <a-watermark v-if="useGetEnv('VITE_APP_ENV') !== 'production'" :content="useGetEnv('VITE_APP_ENV')">
    <a-config-provider :locale="useInitI18n()">
      <HfHeader></HfHeader>
      <div class="main_body">
        <RouterView v-slot="{ Component }">
          <!--          <keep-alive>-->
          <component :is="Component" />
          <!--          </keep-alive>-->
        </RouterView>
      </div>
      <HfFooter></HfFooter>
    </a-config-provider>
  </a-watermark>
  <a-config-provider v-else :locale="useInitI18n()">
    <HfHeader></HfHeader>
    <div class="main_body">
      <RouterView v-slot="{ Component }">
        <component :is="Component" />
      </RouterView>
    </div>
    <HfFooter></HfFooter>
  </a-config-provider>
</template>

<script setup>
import HfFooter from '@/components/layout/HfFooter.vue';
import HfHeader from '@/components/layout/HfHeader.vue';
import { useInitI18n } from '@/hooks/language.js';
import { useCommonCacheStore } from '@/stores/commonCache.js';
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';
import { useCheckIsLogin } from '@/hooks/commomApi.js';
import { useGetEnv } from '@/hooks/utils.js';
import BusinessContextEvent from '@/common/BusinessContextEvent.js';

const commonCache = useCommonCacheStore();
const router = useRouter();

onMounted(() => {
  //监听滑动事件
  window.addEventListener('scroll', (event) => {
    if (document.documentElement.scrollTop > 300) {
      BusinessContextEvent.$emit('hidePopupVisible');
    } else {
      BusinessContextEvent.$emit('showPopupVisible');
    }
    if (router.currentRoute.value.path !== '/') {
      return;
    }
    if (document.documentElement.scrollTop > 10) {
      commonCache.setHeaderWhiteTheme();
    } else {
      commonCache.setHeaderDefaultTheme();
    }
  });
  useCheckIsLogin(() => {}, false);
});
</script>

<style lang="less" scoped>
.main_body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
