import { createApp } from 'vue';
import audio from '@/components/audio/index.vue';
import { v4 as uuidv4 } from 'uuid';
import svgIcon from '@/components/svgIcon/index.vue';

/**
 * 题目解析富文本
 * @type {{beforeUnmount(*, *, *, *), unmounted(*, *, *, *), beforeUpdate(*, *, *, *), created(*, *, *, *), beforeMount(*, *, *, *), mounted(*, *, *, *): void, updated(*, *, *, *): void}}
 */
export const richText = {
  // 在绑定元素的 attribute 前
  // 或事件监听器应用前调用
  created(el, binding, vnode, prevVnode) {
    // 下面会介绍各个参数的细节
  },
  // 在元素被插入到 DOM 前调用
  beforeMount(el, binding, vnode, prevVnode) {},
  // 在绑定元素的父组件
  // 及他自己的所有子节点都挂载完成后调用
  mounted(el, binding, vnode, prevVnode) {
    renderDom(el, binding);
  },
  // 绑定元素的父组件更新前调用
  beforeUpdate(el, binding, vnode, prevVnode) {},
  // 在绑定元素的父组件
  // 及他自己的所有子节点都更新后调用
  updated(el, binding, vnode, prevVnode) {
    renderDom(el, binding);
  },
  // 绑定元素的父组件卸载前调用
  beforeUnmount(el, binding, vnode, prevVnode) {},
  // 绑定元素的父组件卸载后调用
  unmounted(el, binding, vnode, prevVnode) {},
};

/**
 * 渲染dom
 * @param el
 * @param binding
 */
const renderDom = (el, binding) => {
  if (!binding.value) return;
  const uuid = uuidv4();
  const newHtml = binding.value.replaceAll('$UploadImages$', import.meta.env.VITE_APP_IMG_B_BASE_URL);
  el.innerHTML = newHtml;
  const audioSrcList = [];
  let querySelectorAll = el.querySelectorAll('[data-w-e-type]');
  querySelectorAll.forEach((item) => {
    let querySelector = item.querySelector('source');
    audioSrcList.push(querySelector.getAttribute('src'));
    item.remove();
  });

  //图片固定高度
  let querySelectorImg = el.querySelectorAll('img');
  querySelectorImg.forEach((item) => {
    console.log(item);
    item.setAttribute('class', 'homeworkImg');
  });
  el.insertAdjacentHTML('beforeend', `<div id="html_${uuid}">新元素</div>`);
  createApp(audio, {
    audioSrcList: audioSrcList,
    id: 'html_' + uuid,
  })
    .component('SvgIcon', svgIcon)
    .mount(`#html_${uuid}`);
};

export const richTextSrc = {
  // 在绑定元素的 attribute 前
  // 或事件监听器应用前调用
  created(el, binding, vnode, prevVnode) {
    // 下面会介绍各个参数的细节
  },
  // 在元素被插入到 DOM 前调用
  beforeMount(el, binding, vnode, prevVnode) {},
  // 在绑定元素的父组件
  // 及他自己的所有子节点都挂载完成后调用
  mounted(el, binding, vnode, prevVnode) {
    if (!binding.value) return;
    const newHtml = binding.value.replaceAll('$UploadImages$', import.meta.env.VITE_APP_IMG_B_BASE_URL);
    el.innerHTML = newHtml;
  },
  // 绑定元素的父组件更新前调用
  beforeUpdate(el, binding, vnode, prevVnode) {},
  // 在绑定元素的父组件
  // 及他自己的所有子节点都更新后调用
  updated(el, binding, vnode, prevVnode) {
    if (!binding.value) return;
    const newHtml = binding.value.replaceAll('$UploadImages$', import.meta.env.VITE_APP_IMG_B_BASE_URL);
    el.innerHTML = newHtml;
  },
  // 绑定元素的父组件卸载前调用
  beforeUnmount(el, binding, vnode, prevVnode) {},
  // 绑定元素的父组件卸载后调用
  unmounted(el, binding, vnode, prevVnode) {},
};
