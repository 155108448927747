//百度统计
if (import.meta.env.VITE_APP_ENV === 'production') {
  let _hmt = window.hasOwnProperty('_hmt') ? _hmt : [];
  (function () {
    let hm = document.createElement('script');
    hm.src = 'https://hm.baidu.com/hm.js?834fbfa942d035046869ae5779196383';
    let s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(hm, s);
  })();
}
