import { createApp } from 'vue';

import '@/plugins/tongji.js';
import 'amfe-flexible';
import App from './App.vue';
import Success from '@/views/payment/result/success.vue';
import Failure from '@/views/payment/result/failure.vue';
import router from './router';
import '@/assets/css/theme.less';
import '@/assets/css/mian.less';
import '@/assets/css/anime.less';
import '@/assets/font/iconfont/iconfont.css';
import VueAnimXyz from '@animxyz/vue3';
import '@animxyz/core';
import '@/plugins/disabledConsole.js';
import i18n from '@/locales/index.js';
import svgIcon from '@/components/svgIcon/index.vue';
import 'virtual:svg-icons-register';
import pinia from '@/stores/index.js';
import { richText, richTextSrc } from '@/directive/richText.js';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { createHead } from '@unhead/vue';
import Empty from '@/components/empty/index.vue';
import Confirm from '@/components/common/Confirm/Confirm.vue';
import PDFObjectPlugin from 'pdfobject-vue';
import Copy from 'vue3-copy';
import { getUrlParams } from '@/hooks/utils.js';
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

const head = createHead();
import('@/common/NProgress.js');

// (function () {
//   var checkStatus;
//   var element = new Image();
//   element.__defineGetter__('id', function () {
//     checkStatus = 'on';
//   });
//   setInterval(function () {
//     checkStatus = 'off';
//     console.log(element);
//     if (checkStatus === 'on') {
//       document.onkeydown = null;
//       document.oncontextmenu = null;
//     }
//   }, 1000);
// })();

const PAYPAL_SCRIPT = 'https://www.paypal.com/sdk/js?client-id=' + import.meta.env.VITE_APP_PAYPAL_CLIENT_ID + '&components=buttons,messages';
const script = document.createElement('script');
script.setAttribute('src', PAYPAL_SCRIPT);
document.head.appendChild(script);

let href = window.location.href;
let pageType = 'main';
if (href.indexOf('payment/result/success') > -1) {
  pageType = 'success';
} else if (href.indexOf('payment/result/failure') > -1) {
  pageType = 'failure';
}

//移动端的跳转移动端web
console.log('浏览器宽度', window.innerWidth);
const innerWidth = window.innerWidth;
if (innerWidth <= 750) {
  let urlParams = getUrlParams('userCode');
  if (urlParams) {
    window.location.href = 'https://mobile.hanzfeng.com/#/pages/mine/index?userCode=' + urlParams;
  } else {
    window.location.href = 'https://mobile.hanzfeng.com/';
  }
} else {
  const app = createApp(pageType === 'main' ? App : pageType === 'success' ? Success : Failure);
  app.use(pinia);
  app.use(i18n);
  app.use(router);
  app.use(VueAnimXyz);
  app.use(head);
  app.use(VueDOMPurifyHTML);
  app.use(PDFObjectPlugin);
  app.use(LoadingPlugin);
  app.use(Copy);
  app.component('SvgIcon', svgIcon);
  app.component('Empty', Empty);
  app.component('Confirm', Confirm);
  app.mount('#app');

  app.directive('richText', richText);
  app.directive('richTextSrc', richTextSrc);
}
